<template>
  <div class="list-page">
    <div class="list-container">
      <v-list flat class="pa-0 ma-0">
        <v-list-item-group color="primary">
          <template v-for="(item, index) in items">
            <v-list-item :key="item.testDate">
              <template>
                <v-list-item-content @click="onSelectItem(item)">
                  <v-list-item-title class="ml-2 title-item">
                    <div class="d-flex">
                      <div class="mt-1 mr-1"></div>
                      {{ item.title }}
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-2 subtitle-item">
                    {{ formatDate(item.subtitle) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="#d6366e">mdi mdi-chevron-right</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="index < items.length - 1"
              :key="item.subtitle"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
require('moment-timezone');
import { api, auth } from '../../sharedPlugin';

export default {
  data() {
    return {
      items: []
    };
  },
  async mounted() {
    this.$emit('onLoading', true);
    await this.getListData();
    console.log(this.items);
    this.$emit('onLoading', false);
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getListData() {
      // Get the ultrasound list from the API
      // Call the API to retrieve a list of follicular ultrasound reports for a specific patient with different dates.
      await api.Labs.getUSReportList(this.user.patientEhrId, this.user.clinicId)
        .then((data) => {
          // Map over the data and set the title and subtitle properties
          this.items = data.map((item) => {
            return {
              id: item.Patient_ID,
              title: item.Order_Reference_Text,
              subtitle: item.Test_Date
            };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          // Handle the error by throwing an error message
          this.throwError(error);
        });
    },
    throwError(error) {
      this.notification.show = true;
      if (error?.code === 404) {
        this.notification.title = 'No Results Found';
        this.notification.type = 'success';
      } else {
        this.notification.title = 'ERROR';
        this.notification.type = 'error';
      }
      this.notification.message = error?.message ?? error;
      this.isLoading = false;
    },
    formatDate(subtitle) {
      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';
      return moment(subtitle).format('MMM DD, YYYY hh:mma ') + abbr;
    },
    onSelectItem(item) {
      this.$emit('onSelectItem', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-page {
  .list-container {
    height: 92vh;
    overflow-y: scroll;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .item-title {
    height: 41px;
    background: #e7f4f5;
  }
  .title-item {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }
  .subtitle-item {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.0024em;
    color: #000000;
  }
  .label-info {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.0041em;
    color: #000000;
  }
  .v-list-group {
    .v-icon {
      color: #d6366e;
    }
  }
  .v-list-group.v-list-group--active {
    .v-icon {
      color: #1f6070;
    }
  }
  .picker {
    background-color: rgb(214, 54, 110);
    border-color: rgb(214, 54, 110);
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>
