<template>
  <!-- <v-row class="justify-center mt-2">
    <v-progress-circular color="primary" indeterminate />
  </v-row> -->
  <List @onSelectItem="handleSelectedItem" />
</template>

<script>
import List from '../../components/Labs/List';

export default {
  components: {
    List
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    /**
     * Handle the selection of a list item.
     * @param {Object} item - The selected list item.
     */
    handleSelectedItem(item) {
      // Navigate to the labs detail page with the selected item's ID
      this.$router.push({ name: 'Ultrasound', params: { id: item.subtitle } });
    },
    handleLoading(value) {
      this.isLoading = value;
    }
  }
};
</script>

<style lang="scss" scoped></style>
